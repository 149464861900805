<script setup>
    import { defineProps, watch } from 'vue'
    import * as bootstrap from 'bootstrap';


    const props = defineProps({
        alertText: { type: String, default: ''},
        toastType: { type: String, default: 'success'},
    })

    const showAlert = ((type = 'success') => {
        var toastAddType = '.toast' + '.' + type
        var toastElList = [].slice.call(document.querySelectorAll(toastAddType));
        var toastList = toastElList.map(function (toastEl) {
            return new bootstrap.Toast(toastEl)
        })
        toastList.forEach(toast => toast.show())
    })

    watch(() => props.alertText, () => {
        setTimeout(() => {
            showAlert(props.toastType)
        }, 200)
    }, { deep: true });

    

</script>

<template>
    <div class="myToast align-items-center primary border-0 position-fixed bottom-0 start-50 mb-4 translate-middle"
        :class="'toast '+props.toastType"
        role="alert" aria-live="assertive" aria-atomic="true">
        <div class="d-flex">
            <div class="toast-body primary-text">
                <p class="m-0">{{ props.alertText }}</p>
            </div>
            <button type="button" class="btn-close me-2 m-auto p-2" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
    </div>
</template>

<style>

.toast.error {
    background-color: white !important;
}

.toast.error p {
    color: red !important;
}

</style>