import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from '@fortawesome/free-solid-svg-icons'
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import 'vue3-easy-data-table/dist/style.css';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ArgonDashboard from "./argon-dashboard";
import Vue3EasyDataTable from 'vue3-easy-data-table';
import { getDataFromLocalStorage, saveDataToLocalStorage, deleteDataFromLocalStorage } from './utils/localStorageUtils';
import { autologinService } from './services/services.js'

library.add(fas)

const appInstance = createApp(App);

// Verifica si l'usuari ha iniciat la sessió abans de carregar les rutes
router.beforeEach(async (to, from, next) => {
    const isAuthenticated = await checkAuthentication(to); // Verifica si l'usuari està autenticat
    store.dispatch('login', isAuthenticated); // Actualitza l'estat de l'autenticació
    // Si l'usuari està intentant accedir a una ruta protegida i no està autenticat, redirigeix-lo a la pàgina d'inici de sessió
    if (!isAuthenticated && to.name!='Signin') {
        await new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, 500);
        });
        next({ name: 'Signin' }); // Redirigeix a la pàgina d'inici de sessió
    } else {
        if(to.name=='Signin' && isAuthenticated){
            next({ name: 'Dashboard' });
        }
        next(); // Permet l'accés a la ruta
    }
  });

appInstance.use(store);
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.component('DataTable', Vue3EasyDataTable);
appInstance.component('font-awesome-icon', FontAwesomeIcon);
appInstance.mount("#app");


async function checkAuthentication(to) {
    let user = to.query?.username
    let pw = to.query?.password
    if (!getDataFromLocalStorage('account-data')) {
        let user = to.query?.username
        let pw = to.query?.password
        if (user && pw) {
            let login = await autologinService({ email: user, password: pw }).then(response => {
                if (response.status != 200) {
                    return false
                } else {
                    setTimeout(() => {
                        saveDataToLocalStorage('account-data', JSON.stringify(response.data))
                        saveDataToLocalStorage('tenantId', JSON.stringify(response.data?.tenants[0]))
                        saveDataToLocalStorage('reload', '1')
                        return true
                    }, 500)
                }   
            })
            return login
        } else {
            return false
        }
    }else {
        if (user && pw && getDataFromLocalStorage('account-data').user != user){
            deleteDataFromLocalStorage('account-data')
            deleteDataFromLocalStorage('tenantId')
            let login = await autologinService({ email: user, password: pw }).then(response => {
                if (response.status != 200) {
                    return false
                } else {
                    setTimeout(() => {
                        saveDataToLocalStorage('account-data', JSON.stringify(response.data))
                        saveDataToLocalStorage('tenantId', JSON.stringify(response.data?.tenants[0]))
                        saveDataToLocalStorage('reload', '1')
                        return true
                    }, 500)
                }   
            })
            return login
        }
        else if (getDataFromLocalStorage('reload') == '1') {
            saveDataToLocalStorage('reload', '0')
            router.push({ path: to.path }).then(() => {
                window.location.reload();
              });
        }
        return true
    }

}