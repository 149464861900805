import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Gateways from "../views/Gateways.vue";
import GatewayDetail from "../views/GatewayDetail.vue";
import GatewayCreate from "../views/GatewayCreate.vue";
import Applications from "../views/Applications.vue";
import ApplicationDetail from "../views/ApplicationDetail.vue";
import ApplicationCreate from "../views/ApplicationCreate.vue";
import DeviceAdd from "../views/DeviceAdd.vue";

import DeviceDetail from "../views/DeviceDetail.vue";
import Signin from "../views/Signin.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/gateways",
    name: "Gateways",
    component: Gateways,
  },
  {
    path: "/gateways/:id",
    name: "Detall Gateway",
    component: GatewayDetail,
  },
  {
    path: "/gateways/create",
    name: "Crear Gateway",
    component: GatewayCreate,
  },
  {
    path: "/applications",
    name: "Aplicacions",
    component: Applications,
  },
  {
    path: "/applications/:id",
    name: "Detall Aplicació",
    component: ApplicationDetail,
  },
  {
    path: "/applications/create",
    name: "Crear Aplicació",
    component: ApplicationCreate,
  },
  {
    path: "/applications/:appId/device/create",
    name: "Crear Dispositiu",
    component: DeviceAdd,
  },
  {
    path: "/applications/:appId/device/:id",
    name: "Detall Dispositiu",
    component: DeviceDetail,
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
