<script setup>
// import setPopovers from "@/assets/js/popovers.js";
// import ArgonButton from "@/components/ArgonButton.vue";
import { ref } from "vue";
import bootstrap from "bootstrap/dist/js/bootstrap";


defineProps({
    color: {
        type: String,
        default: "success",
    },
    size: {
        type: String,
        default: "md",
    },
    variant: {
        type: String,
        default: "fill",
    },
    fullWidth: {
        type: Boolean,
        default: false,
    },
    active: {
        type: Boolean,
        default: false,
    },
    buttonText: {
        type: String,
        default: ''
    },
    content:{
        type: String,
        default: ''
    },
    placement:{
        type: String,
        default: 'left'
    },
});

const getClasses = (variant, color, size, fullWidth, active) => {
    let colorValue, sizeValue, fullWidthValue, activeValue;

    // Setting the button variant and color
    if (variant === "gradient") {
        colorValue = `bg-gradient-${color}`;
    } else if (variant === "outline") {
        colorValue = `btn-outline-${color}`;
    } else {
        colorValue = `btn-${color}`;
    }

    sizeValue = size ? `btn-${size}` : null;

    fullWidthValue = fullWidth ? `w-100` : null;

    activeValue = active ? `active` : null;

    return `${colorValue} ${sizeValue} ${fullWidthValue} ${activeValue}`;
};

const buttonRef = ref(null);
const popoverRef = ref(null);
const popoverOpen = ref(false);

const togglePopover = () => {
    popoverOpen.value = !popoverOpen.value;
    if (popoverOpen.value && popoverRef.value != null) {
        popoverRef.value.classList.add('show')
        // Only initialize popover when popoverRef is not set
        const button = buttonRef.value;
        new bootstrap.Popover(button, {
            container: popoverRef.value  // Use document.body as container
        });
        const popoverRect = popoverRef.value.getBoundingClientRect()
        let halfHeight = popoverRect.height/2
        let popoverWidth = popoverRect.width + 10
        popoverRef.value.style.transform = `translateX(-${popoverWidth}px) translateY(-${halfHeight}px)`;
    }else{
        popoverRef.value.classList.remove('show')
    }
};

</script>
<template>
    <div class="position-relative d-inline">
        <button ref="buttonRef" type="button" class="btn" :class="getClasses(variant, color, size, fullWidth, active)" @click="togglePopover">
        {{ buttonText }}
        </button>

        <div ref="popoverRef" class="popover fade bs-popover-left">
            <button id="popup-closer" class="ol-popup-closer btn m-1 px-1 py-0 no-background" @click="togglePopover"><font-awesome-icon
                icon="xmark" class="icon" /></button>
            <div class="popover-arrow"></div>
            <div class="popover-body">
            <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>

<style>
.popover {
    z-index: -1 !important;
}
.popover.show {
    z-index: 1000 !important;
}
/* .container {padding:20px;}
.popover {width:350px;max-width:1500px;}
.popover-content h4 {
  color: #00A1FF;
}
.popover-content h4 small {
  color: black;
}
.popover-content button.btn-primary {
  color: #00A1FF;
  border-color:#00A1FF;
  background:white;
}

.popover-content button.btn-default {
  color: gray;
  border-color:gray;
}
.bs-popover-auto[x-placement^=right] .popover-arrow, .bs-popover-left .popover-arrow {
    right: calc((.5rem + 1px)* -1);
    width: .5rem;
    height: 1rem;
    margin: .3rem 0;
} */
</style>
