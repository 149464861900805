<script setup>
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart, LineChart, BarChart } from 'echarts/charts';
import {
    TitleComponent,
    TooltipComponent,
    ToolboxComponent,
    LegendComponent,
    GridComponent,
    DataZoomComponent,
} from 'echarts/components';
import VChart, { THEME_KEY } from 'vue-echarts';
import { provide } from 'vue';

use([
    CanvasRenderer,
    BarChart,
    PieChart,
    LineChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    GridComponent,
    ToolboxComponent,
    DataZoomComponent
]);

provide(THEME_KEY, 'light');

const props = defineProps({
    options: { type: Object, default: () => {} },
})

</script>

<template>
    <v-chart class="m-0 p-0" :option="props.options" autoresize />
</template>

<style scoped>
.chart, .echarts {
    height: 100vh;
}
</style>