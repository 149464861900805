<script setup>
</script>

<template>
	<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 792 612" style="enable-background:new 0 0 792 612;" xml:space="preserve">
	<g>
		<path class="st0" d="M262.02,330.32c1.38,0,2.08,0.78,2.08,2.32v85.07c0,1.22-0.29,2.08-0.86,2.57c-0.57,0.49-1.51,0.73-2.81,0.73
			h-18.09c-1.14,0-1.92-0.22-2.32-0.67c-0.41-0.45-0.61-1.16-0.61-2.14v-85.31c0-0.81,0.18-1.45,0.55-1.89
			c0.37-0.45,0.87-0.67,1.53-0.67H262.02z"/>
		<path class="st0" d="M294.96,416.61c-6.48-3.75-11.47-9.11-14.97-16.07c-3.5-6.97-5.26-15.17-5.26-24.63
			c0-9.45,1.77-17.7,5.32-24.75c3.54-7.05,8.56-12.49,15.03-16.32c6.48-3.83,14.03-5.74,22.67-5.74c8.56,0,16.07,1.92,22.55,5.74
			c6.48,3.83,11.49,9.27,15.03,16.32c3.54,7.05,5.32,15.3,5.32,24.75c0,9.37-1.75,17.54-5.26,24.5c-3.5,6.97-8.49,12.34-14.97,16.13
			c-6.48,3.79-14.04,5.68-22.67,5.68C309.04,422.23,301.44,420.36,294.96,416.61z M330.77,398.4c2.65-4.48,3.97-11.86,3.97-22.12
			c0-10.59-1.37-18.27-4.09-23.04c-2.73-4.77-7.03-7.15-12.89-7.15c-5.95,0-10.29,2.38-13.02,7.15c-2.73,4.77-4.09,12.45-4.09,23.04
			c0,10.27,1.34,17.64,4.03,22.12c2.69,4.48,7.09,6.72,13.2,6.72C323.82,405.12,328.12,402.88,330.77,398.4z"/>
		<path class="st0" d="M362.49,332.88c0-0.73,0.22-1.34,0.67-1.83c0.45-0.49,1.12-0.73,2.02-0.73h71.74c0.9,0,1.57,0.24,2.02,0.73
			c0.45,0.49,0.67,1.1,0.67,1.83v12.96c0,1.39-0.82,2.08-2.44,2.08h-22.24c-0.9,0-1.34,0.41-1.34,1.22v69.42
			c0,1.63-0.73,2.44-2.2,2.44h-20.53c-1.39,0-2.08-0.77-2.08-2.32v-69.06c0-0.65-0.08-1.1-0.24-1.34c-0.16-0.24-0.49-0.37-0.98-0.37
			h-22.49c-1.71,0-2.57-0.69-2.57-2.08V332.88z"/>
		<path class="st0" d="M501.58,332.64c5.38,2.36,9.78,5.64,13.2,9.84c3.42,4.2,5.62,8.9,6.6,14.12l0.25,2.08
			c0,0.65-0.12,1.08-0.37,1.28c-0.24,0.2-0.69,0.35-1.34,0.43l-19.07,1.34c-0.73,0-1.2-0.14-1.41-0.43c-0.2-0.28-0.43-0.87-0.67-1.77
			c-1.87-8.96-6.64-13.44-14.3-13.44c-11.49,0-17.23,9.66-17.23,28.97c0,10.27,1.42,17.7,4.28,22.31c2.85,4.6,7.17,6.91,12.96,6.91
			c4.56,0,8.45-1.16,11.67-3.48c3.22-2.32,5.89-6.13,8.01-11.43c0.24-0.65,0.86-0.98,1.83-0.98c0.49,0,0.9,0.04,1.22,0.12l13.81,2.69
			c0.49,0.16,0.87,0.41,1.16,0.73c0.28,0.33,0.43,0.9,0.43,1.71c0,0.49-0.04,0.9-0.12,1.22c-0.65,3.26-2.47,7.01-5.44,11.24
			c-2.98,4.24-7.25,7.99-12.83,11.24c-5.58,3.26-12.41,4.89-20.47,4.89c-8.48,0-15.91-2.02-22.31-6.05
			c-6.4-4.03-11.33-9.63-14.79-16.81c-3.46-7.17-5.19-15.4-5.19-24.69c0-9.21,1.73-17.25,5.19-24.14
			c3.46-6.88,8.37-12.18,14.73-15.89c6.36-3.71,13.85-5.56,22.49-5.56C490.29,329.1,496.2,330.28,501.58,332.64z"/>
		<path class="st0" d="M549.79,416.61c-6.48-3.75-11.47-9.11-14.97-16.07c-3.5-6.97-5.26-15.17-5.26-24.63
			c0-9.45,1.77-17.7,5.32-24.75c3.54-7.05,8.56-12.49,15.03-16.32c6.48-3.83,14.03-5.74,22.67-5.74c8.56,0,16.07,1.92,22.55,5.74
			c6.48,3.83,11.49,9.27,15.03,16.32c3.54,7.05,5.32,15.3,5.32,24.75c0,9.37-1.75,17.54-5.26,24.5c-3.5,6.97-8.49,12.34-14.97,16.13
			c-6.48,3.79-14.04,5.68-22.67,5.68C563.86,422.23,556.27,420.36,549.79,416.61z M585.6,398.4c2.65-4.48,3.97-11.86,3.97-22.12
			c0-10.59-1.37-18.27-4.09-23.04c-2.73-4.77-7.03-7.15-12.89-7.15c-5.95,0-10.29,2.38-13.02,7.15c-2.73,4.77-4.09,12.45-4.09,23.04
			c0,10.27,1.34,17.64,4.03,22.12c2.69,4.48,7.09,6.72,13.2,6.72C578.65,405.12,582.95,402.88,585.6,398.4z"/>
		<path class="st0" d="M626.97,420.15c-0.49-0.57-0.73-1.34-0.73-2.32v-84.94c0-0.9,0.18-1.55,0.55-1.96
			c0.37-0.41,0.96-0.61,1.77-0.61h29.94c0.98,0,1.67,0.14,2.08,0.43c0.41,0.29,0.77,0.84,1.1,1.65l15.4,44.73
			c0.24,0.65,0.53,0.98,0.86,0.98c0.32,0,0.61-0.32,0.86-0.98l14.3-43.63c0.65-2.12,1.67-3.18,3.06-3.18h30.92
			c1.38,0,2.08,0.78,2.08,2.32v85.19c0,1.14-0.27,1.96-0.79,2.44c-0.53,0.49-1.45,0.73-2.75,0.73h-18.21c-1.14,0-1.92-0.22-2.32-0.67
			c-0.41-0.45-0.61-1.16-0.61-2.14v-57.69c0-0.57-0.16-0.86-0.49-0.86c-0.33,0-0.61,0.33-0.86,0.98l-19.31,58.3
			c-0.49,1.39-1.43,2.08-2.81,2.08H669.5c-1.47,0-2.44-0.69-2.93-2.08l-19.31-57.44c-0.24-0.73-0.49-1.1-0.73-1.1
			c-0.33,0-0.49,0.41-0.49,1.22v56.96c0,0.98-0.16,1.63-0.49,1.96c-0.33,0.33-1.02,0.49-2.08,0.49h-14.42
			C628.15,421.01,627.46,420.72,626.97,420.15z"/>
	</g>
	<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="262.1585" y1="110.1038" x2="102.8393" y2="346.0186">
		<stop  offset="0.0144" style="stop-color:#22A7A7"/>
		<stop  offset="0.9999" style="stop-color:#502276"/>
	</linearGradient>
	<path class="st1" d="M91.78,143.98c11.75-2.48,23.93-3.78,36.42-3.78c83.32,0,153.1,57.96,171.23,135.75l32.29-6.81
		c-21.29-93.21-104.7-162.76-204.36-162.76c-14.59,0-28.84,1.49-42.59,4.33L91.78,143.98z"/>
	<linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="263.0689" y1="110.7186" x2="103.7497" y2="346.6335">
		<stop  offset="0.0144" style="stop-color:#22A7A7"/>
		<stop  offset="0.9999" style="stop-color:#502276"/>
	</linearGradient>
	<path class="st2" d="M105.04,206.85c7.47-1.58,15.22-2.41,23.16-2.41c52.38,0,96.34,36.1,108.34,84.78l32.28-6.81
		c-15.15-63.62-72.35-110.94-140.61-110.94c-10.27,0-20.3,1.07-29.97,3.11L105.04,206.85z"/>
	<linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="263.6828" y1="111.1332" x2="104.3636" y2="347.0481">
		<stop  offset="0.0144" style="stop-color:#22A7A7"/>
		<stop  offset="0.9999" style="stop-color:#502276"/>
	</linearGradient>
	<path class="st3" d="M205.08,295.86c-8.92-34.12-39.96-59.29-76.87-59.29c-5.67,0-11.2,0.59-16.54,1.72l6.62,31.36
		c3.07-0.63,6.24-0.96,9.49-0.96c21.42,0,39.48,14.35,45.1,33.96L205.08,295.86z"/>
	<path class="st4" d="M175.11,315.13c0.01,0.29,0.01,0.59,0.01,0.88c0,25.91-21,46.91-46.91,46.91s-46.91-21-46.91-46.91
		c0-18.02,10.16-33.66,25.06-41.52l-6.76-32.07c-29.35,11.81-50.18,41.06-50.18,75.3c0,13.27,6.74,29.43,16.6,45.39
		c21.57,34.93,57.5,69.43,57.5,69.43c2.78,2.67,7.17,2.67,9.95,0c0,0,35.93-34.5,57.5-69.43c9.86-15.96,16.6-32.12,16.6-45.39
		c0-3.15-0.18-6.26-0.52-9.32L175.11,315.13z"/>
	</svg>
</template>

<style type="text/css">
	.st0{fill:#502276;}
	.st1{fill-rule:evenodd;clip-rule:evenodd;fill:url(#SVGID_1_);}
	.st2{fill-rule:evenodd;clip-rule:evenodd;fill:url(#SVGID_2_);}
	.st3{fill-rule:evenodd;clip-rule:evenodd;fill:url(#SVGID_3_);}
	.st4{fill-rule:evenodd;clip-rule:evenodd;fill:#502276;}
</style>