<script setup>
import { ref, onMounted } from "vue";
import MiniStatisticsCard from "./components/MiniStatisticsCard.vue";
import Map from "@/components/Maps.vue";
import gatewayIcon from "@/assets/img/emptyGateway.png";
import gatewayErrorIcon from "@/assets/img/emptyGatewayError.png";
import EChartComponent from '@/components/EChart.vue';
import Spinner from "@/components/Spinner.vue";
import { getGateways, getGatewaysCount, getApplications, getDevicesCount, getEventsByGateways } from "../services/services";

const gatewaysCount = ref(0)
const gateways = ref([])
const applications = ref([])
const devices = ref(0)
const activeDevices = ref(0)
const gatewaysMarkers = ref([])
const showMap = ref(false)
const chartEvents = ref({})
const chartEventsLoading = ref(false)
const chartEventsLoaded = ref(false)
let chartColors = {}

onMounted(() => {
    chartEventsLoading.value = true
    getGatewaysCount().then(response => {
        gatewaysCount.value = response.data
        getGateways(0,gatewaysCount.value, true).then(response => {
            gateways.value = response.data
            response.data.forEach(gateway => {
                chartColors[ gateway.gateway_id ] = gateway.tags['color'] || null;
                gatewaysMarkers.value.push({
                    lat: gateway.location?.lat,
                    lng: gateway.location?.long,
                    icon: gateway.state == '1' ? gatewayIcon : gatewayErrorIcon,
                    content: `<p><small>`+gateway.name+`<br>`+gateway.gateway_id+`</small></p>`,
                    color: gateway.tags['color'] || 'green',
                })
            })
            showMap.value = true
            getEventsByGateways().then(response => {        
                // nou bloc per echart, contruïm les series i l'eix x
                let eix_x_dies = new Set();
                let gateway_color = {};
                let gateway_per_dia = {}; // serà una estructura "gateway": {"date": "valor"}
                response.data.forEach(item => {
                    eix_x_dies.add(item.date);
                    if( typeof(gateway_per_dia[ item.gateway_name ]) == "undefined") gateway_per_dia[ item.gateway_name ] = {};
                    gateway_per_dia[ item.gateway_name ][ item.date ] = item.count;
                    gateway_color[ item.gateway_name ] = chartColors[ item.gateway_id ];
                });
                eix_x_dies = [...eix_x_dies]; // convertim el Set() en array

                const series = [];
                
                Object.entries(gateway_per_dia).forEach(([gateway_name, item]) => {
                    var dades = [];
                    eix_x_dies.forEach((dia) => {
                        dades.push({
                            "value" : item[dia] || '-',
                            itemStyle: {
                                color: gateway_color[ gateway_name ]
                            },
                        });
                    });
                    series.push({
                        type: 'bar',
                        stack: 'a',
                        name: gateway_name,
                        data: dades
                    });

                });
                chartEvents.value = {
                    height: "300px",
                    options: { 
                        xAxis: {
                            type: 'category',
                            data: eix_x_dies
                        },
                        yAxis: {
                            name: "Paquets tx",
                            type: 'value',
                            position: 'left',
                        },
                        tooltip: {
                            show: true,
                        },
                        series: series,
                        // legend: {
                        //     // Try 'horizontal'
                        //     orient: 'horizontal',
                        //     right: 10,
                        //     top: 'center'
                        // },
                    }
                }
                chartEventsLoading.value = false
                chartEventsLoaded.value = true
            })
        })
    })
    
    getDevicesCount().then(response => {
        devices.value = response.data.all
        activeDevices.value = response.data.active
    })
    getApplications().then(response => {
        applications.value = response.data
    })

   
})
</script>
<template>
    <div class="py-4 container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-12">
                        <mini-statistics-card title="Gateways" 
                            :value="gateways.filter(g => g.state == '1').length+'/'+gatewaysCount" 
                            description="Gateways actives" 
                            :icon="{
                                component: 'wifi',
                                background: 'bg-gradient-primary',
                                shape: 'rounded-circle',
                            }" />
                    </div>
                    <div class="col-lg-4 col-md-6 col-12">
                        <mini-statistics-card title="Aplicacions" :value="applications.length" description="" :icon="{
                            component: 'border-none',
                            background: 'bg-gradient-danger',
                            shape: 'rounded-circle',
                        }" />
                    </div>
                    <div class="col-lg-4 col-md-6 col-12">
                        <mini-statistics-card title="Dispositius Actius" :value="activeDevices+'/'+devices" description="" :icon="{
                            component: 'hard-drive',
                            background: 'bg-gradient-warning',
                            shape: 'rounded-circle',
                        }" />
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="card overflow-auto" style="min-height: 550px;">
                            <Map v-if="showMap" id="gatewayMap" class="mb-3" :markers="gatewaysMarkers"
                                style="width: 100%; height: 550px; z-index: 1">
                            </Map>
                            <spinner class="mt-3" v-else />
                        </div>
                    </div>
                    <div class="col">
                        <div class="card overflow-auto p-3" style="height: 550px;">
                            <div v-if="!chartEventsLoading">
                                <p v-if="chartEventsLoaded && chartEvents.options.series.length > 0"
                                     class="mb-0 text-sm text-uppercase text-center font-weight-bold">
                                     Número de paquets transmesos l'última setmana
                                </p>
                                <EChartComponent style="max-height: 475px;" v-if="chartEventsLoaded && chartEvents.options.series.length > 0" 
                                    :options="chartEvents.options">
                                </EChartComponent>
                                <div v-else><p class="text-center mt-5">No hi ha dades de trames</p></div>
                            </div>
                            <spinner v-else />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
