<script setup>
import { ref } from "vue";
import Tabs from "@/components/Tabs.vue";
import DeviceCreate from "./DeviceCreate.vue";
import DevicesCreate from "./DevicesCreate.vue";

const tabsInfo = ref([
    { text: "Afegir un dispositiu", value: "one" },
    { text: "Afegir més d'un dispositiu", value: "moreThanOne" },
])
</script>

<template>
    <div class="container-fluid pt-4">
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header pb-0 p-3">
                        <div class="row">
                            <div class="col-6 d-flex align-items-center">
                                
                            </div>
                            <div class="col-6 text-end">
                                
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-4 pt-0 pb-2" style="min-height: 560px;">
                        <tabs :tabs-info="tabsInfo">
                            <template #one>
                                <device-create></device-create>
                            </template>
                            <template #moreThanOne>
                                <devices-create></devices-create>
                            </template>
                        </tabs>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>