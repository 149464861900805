<script setup>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { deleteDataFromLocalStorage, getDataFromLocalStorage } from '../../utils/localStorageUtils';
import Breadcrumbs from "@/components/Breadcrumbs.vue";

const store = useStore();
const user = ref(null)

const route = useRoute();

const currentRouteName = computed(() => {
    return route.name;
});
const currentDirectory = computed(() => {
    let dir = route.path.split("/")[1];
    return dir.charAt(0).toUpperCase() + dir.slice(1);
});

const minimizeSidebar = () => store.commit("sidebarMinimize");

const logOut = (() => {
    deleteDataFromLocalStorage('account-data')
    deleteDataFromLocalStorage('tenantId')
    window.location.reload()
})

onMounted(() => {
    user.value = getDataFromLocalStorage('account-data')?.user
})

</script>
<template>
    <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl"
        v-bind="$attrs" id="navbarBlur" data-scroll="true">
        <div class="px-3 py-1 container-fluid">
            <breadcrumbs :current-page="currentRouteName" :current-directory="currentDirectory" />

            <div class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4 me-sm-4"
                id="navbar">
                <div class="pe-md-3 d-flex align-items-center ms-md-auto" v-if="user">
                    <p class="text-white mb-0"><span class="d-sm-inline d-none">{{ user }}</span></p>

                </div>
                <ul class="navbar-nav justify-content-end">
                    <li class="nav-item d-flex align-items-center">
                        <a type="button" class="text-white" @click="logOut">
                            <i class="fa fa-user me-sm-2"></i>
                            <span class="d-sm-inline d-none">Tancar sessió</span>
                        </a>
                    </li>
                    <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
                        <a href="#" @click="minimizeSidebar" class="p-0 nav-link text-white" id="iconNavbarSidenav">
                            <div class="sidenav-toggler-inner">
                                <i class="sidenav-toggler-line bg-white"></i>
                                <i class="sidenav-toggler-line bg-white"></i>
                                <i class="sidenav-toggler-line bg-white"></i>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>
