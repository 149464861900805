<script setup>
import { ref, onMounted, reactive } from 'vue';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Overlay from 'ol/Overlay';
import { Icon, Style, Circle, Fill, Stroke } from 'ol/style';
import { transform } from 'ol/proj';

const map = ref(null);
const vectorSource = ref(null);
// const center = ref(null)

const state = reactive({
    map: null,
});

const props = defineProps({
    id: { type: String, default: 'mapContainer' },
    zoom: { type: Number, default: 11 },
    fitBounds: { type: Boolean, default: true },
    markers: { type: Array, default: () => {} },
    markersGroup: { type: Boolean, default: false },
    layers: { type: Array, default: () => {} },
    popupTitle: { type: String, default: '' },
    showGreenState: { type: Boolean, default: false }
})

const getCenterOfCoordinates = (coordinates) => {
    if (coordinates.length === 0) {
        return null; // No coordinates to calculate the center
    }

    // Initialize variables to accumulate the sum of latitudes and longitudes
    let sumLat = 0;
    let sumLon = 0;

    for (const coordinate of coordinates) {
        sumLat += coordinate[1]; // Latitude is at index 1
        sumLon += coordinate[0]; // Longitude is at index 0
    }

    // Calculate the average latitude and longitude
    const avgLat = sumLat / coordinates.length;
    const avgLon = sumLon / coordinates.length;

    return [avgLon, avgLat]; // Return as [longitude, latitude]
}

const initMap = (() => {
    vectorSource.value?.clear();
    const mapInstance = new Map({
        target: map.value,
        layers: [
            new TileLayer({
                source: new OSM(),
            }),
        ],
        view: new View({
            zoom: props.zoom,
            // center: transform([2.2537604,41.9281887], 'EPSG:4326', 'EPSG:3857'),
            constrainResolution: true,
        }),
        controls: [],
    });

    // Create a source for vector features (markers)
    vectorSource.value = new VectorSource();

    // Create a vector layer to display the markers
    const vectorLayer = new VectorLayer({
        source: vectorSource.value,
    });

    // Add the vector layer to the map
    mapInstance.addLayer(vectorLayer);

    markersAdd()
    // c.forEach(clusterL => {
    //     mapInstance.addLayer(clusterL);
    // });

    var container = document.getElementById('popup'),
        content_element = document.getElementById('popup-content-content'),
        closer = document.getElementById('popup-closer');

    closer.onclick = function () {
        overlay.setPosition(undefined);
        closer.blur();
        return false;
    };

    var overlay = new Overlay({
        element: container,
        autoPan: true,
        offset: [0, -10]
    });

    mapInstance.addOverlay(overlay);
    mapInstance.on('click', function (evt) {
        var feature = mapInstance.forEachFeatureAtPixel(evt.pixel,
            function (feature) {
                return feature;
            });
        if (feature) {
            if (feature.getProperties().noClickable) {
                return
            }
            var geometry = feature.getGeometry();
            var coord = geometry.getCoordinates();
            var fs = feature.getProperties().features
            var content = ''
            if (fs) {
                fs.sort((a, b) => new Date(a.values_?.data.order) - new Date(b.values_?.data.order))
                fs.forEach((f) => {
                    content += f.values_.data.content
                })
            } else {
                content += feature.getProperties().data.content
            }
            content_element.innerHTML = content;
            overlay.setPosition(coord);
        }
    });

    // Canviem el cursor al pasar sobre l'element
    mapInstance.on('pointermove', function (e) {
        const pixel = mapInstance.getEventPixel(e.originalEvent);
        const hit = mapInstance.hasFeatureAtPixel(pixel);
        mapInstance.getTarget().style.cursor = hit ? 'pointer' : '';
    });


    if (props.markers.length > 0) {
        const coordinates = props.markers.map((marker) => [marker.lng, marker.lat]);
        const center = getCenterOfCoordinates(coordinates);
        if (center) {
            mapInstance.getView().setCenter(transform(center, 'EPSG:4326', 'EPSG:3857'));
            mapInstance.getView().setZoom(props.zoom); // or any desired zoom level
        }
    }

    map.value = mapInstance;
    state.map = mapInstance;
})

const markersAdd = (() => {
    props.markers.forEach(marker => {
        const color = marker.color;
        const markerFeature = new Feature({
            geometry: new Point(transform([marker.lng, marker.lat], 'EPSG:4326', 'EPSG:3857')),
            data: {
                content: marker.content,
                color: marker?.color,
            },
            onClick: marker.onClick,
        });

        // Define the style for the marker (icon)
        if (marker.icon) {
            var iconStyle = new Style({
                image: new Icon({
                    opacity: 1,
                    src: marker.icon,
                    scale: 0.2
                })
            });

            var circleStyle = new Style({
                image: new Circle({
                    radius: 14,
                    fill: new Fill({
                        color: color ?? '#404a85',
                    }),
                    stroke: new Stroke({
                        color: 'grey',
                        width: 1,
                    }),
                })
            });

            // var badgeCircle = new Style({
                // renderer: function(pixelCoordinates, state) {
                //     const context = state.context;
                //     context.save(); // Save the current context state

                //     // Translate context to the feature point plus the offset
                //     context.translate(pixelCoordinates[0] + 10, pixelCoordinates[1] -10);

                //     // Draw the circle
                //     context.beginPath();
                //     context.arc(0, 0, 5, 0, 2 * Math.PI, true);
                //     context.fillStyle = colorState;
                //     context.fill();

                //     context.restore(); // Restore the context to its original state
                // }
            // })

            markerFeature.setStyle([circleStyle, iconStyle]);
        } else {
            markerFeature.setStyle(
                new Style({
                    image: new Circle({
                        radius: 8,
                        fill: new Fill({
                            color: color ?? '#404a85', // Set the marker color based on the group
                        }),
                        stroke: new Stroke({
                            color: 'grey',
                            width: 1,
                        }),
                    }),
                })
            );
        }
        vectorSource.value.addFeature(markerFeature);
    })
})

onMounted(() => {
    initMap()
});

</script>

<template>
    <div ref="map" class="ol-map" style="margin-bottom: 0 !important;">
        <div id="popup" class="ol-popup p-0 border">
            <div class="w-100">
                <!-- <p :class="(props.popupTitle) ? 'primary p-1 m-0' : 'm-0'"><strong>{{ props.popupTitle }}</strong></p> -->
                <button id="popup-closer" class="ol-popup-closer btn px-1 py-0 no-background"><font-awesome-icon
                        icon="xmark" class="icon" /></button>
            </div>
            <div id="popup-content">
                <div id="popup-content-content" class="p-3"></div>
            </div>
        </div>
    </div>
</template>

<style scoped></style>