<script setup>
import { onMounted, ref } from 'vue';
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonTextarea from "@/components/ArgonTextarea.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonLoadingButton from "@/components/ArgonLoadingButton.vue";
import { useRouter } from 'vue-router';
import Toast from "@/components/Toast.vue";
import { postGateway } from "../services/services.js";
import { getDataFromLocalStorage } from '../utils/localStorageUtils';

const props = defineProps({
    formData: { type: Object, default: () => { } },
    formCreate: { type: Boolean, default: true },
})

const formData = {
    gateway_id: props.formData && props.formData.gateway_id ? props.formData.gateway_id : '',
    name: props.formData && props.formData.name ? props.formData.name : '',
    description: props.formData && props.formData.description ? props.formData.description : '',
    stats_interval: props.formData && props.formData.stats_interval ? props.formData.stats_interval : 30,
    tags: props.formData && props.formData.tags ? props.formData.tags : {},
};

const router = useRouter();
const errors = ref([])
const tags = ref(Object.entries(formData.tags).map(([key, value]) => ({ key, value })));
const is_admin = ref(false)
const is_loading = ref(false)
const toastType = ref('success')
const alertText = ref("")

const showAlert = ((text, type = 'success') => {
    toastType.value = type
    alertText.value = text
})

const addTag = (() => {
    tags.value.push({
        key: '',
        value: null
    })
})

const removeTag = (index) => {
  tags.value.splice(index, 1);
};

const addGateway = (() => {
    is_loading.value = true
    let hasError = false
    errors.value = []
    if(!formData.name){
        errors.value['name'] = true
        hasError = true
    }
    if(!formData.gateway_id){
        errors.value['gateway_id'] = true
        hasError = true
    }
    if(!formData.stats_interval){
        errors.value['stats_interval'] = true
        hasError = true
    }
    if (hasError){
        is_loading.value = false
        return
    }
    formData.tags = tags.value.reduce((acc, obj) => {
        acc[obj.key] = obj.value;
        return acc;
    }, {})
    postGateway(formData, props.formCreate).then(response => {
        is_loading.value = false
        if(response.status != 200){
            showAlert("Hi ha hagut un error: "+response.data, 'error')
        }else{
            if(props.formCreate){
                showAlert("S'ha afegit la gateway correctament")
            }else{
                showAlert("S'ha actualitzat la gateway correctament")
            }
            setTimeout(() => {
                if(props.formCreate){
                    router.push({ name: 'Gateways' });
                }else{
                    window.location.reload();
                }
            }, 2000)
        }
    })
})

onMounted(() => {
    is_admin.value = getDataFromLocalStorage('account-data').is_admin
})

</script>
<template>
    <div class="container-fluid pt-4 mb-3">
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header pb-2 p-3 px-4">
                        <div class="row">
                            <div class="col-6 d-flex align-items-center">
                                <h6>Afegir una Gateway</h6>
                            </div>
                            <div class="col-6 text-end">
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-4 pt-0 pb-2">
                        <form role="form">
                            <label for="name"><span class="obligatory">*</span> Nom</label>
                            <argon-input id="name" isRequired type="text" :disabled="!is_admin" :error="errors['name']" v-model="formData.name" required aria-label="Name" />
                            <argon-textarea id="description" type="text" :disabled="!is_admin"
                                aria-label="Description" v-model="formData.description">Descripció</argon-textarea>
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="gateway_id"><span class="obligatory">*</span> Gateway ID</label>
                                    <argon-input id="gateway_id" :disabled="!formCreate" isRequired type="text" :error="errors['gateway_id']" v-model="formData.gateway_id" required aria-label="Gateway ID" />
                                </div>
                                <div class="col-md-6">
                                    <label for="stats_interval"><span class="obligatory">*</span> Stats interval (secs)</label>
                                    <argon-input id="stats_interval" :disabled="!is_admin" class="w-25" isRequired type="number" :error="errors['stats_interval']" v-model="formData.stats_interval" required aria-label="stats_interval" />
                                </div>
                            </div>
                       
                            <label for="tag">Tags</label>
                            <div class="border p-3" style="border-radius: 10px;">
                                <div class="row" v-for="(tag, index) in tags" :key="index">
                                    <div class="col-md-3">
                                        <argon-input :id="'tag_index_'+tag.key" type="text" :disabled="!is_admin"
                                            v-model="tags[index].key" aria-label="Value" />
                                    </div>
                                    <div class="col-md-8">
                                        <argon-input :id="'tag_value_'+tag.key" type="text" :disabled="!is_admin"
                                            v-model="tags[index].value" aria-label="Value" />
                                    </div>
                                    <div class="col-md-1">
                                        <argon-button color="white" variant="outline" type="button" :disabled="!is_admin" class="mb-2 text-warning p-2" @click="removeTag(index)">
                                            <font-awesome-icon icon="trash" class="text-warning text-sm opacity-10" />
                                        </argon-button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <argon-button color="success" :disabled="!is_admin" size="xs" variant="outline" type="button" class="text-xs" @click="addTag">
                                            <font-awesome-icon icon="plus" class="text-success text-xs opacity-10" />
                                        </argon-button>
                                    </div>
                                </div>
                            </div>
                      
                            <p><small class="obligatory">* Dades obligatòries</small></p>
                            <div class="">
                                <argon-button color="success" variant="dark" type="button" class="my-4 mb-2" @click="router.push({ name: 'Gateways' });">
                                    Tornar
                                </argon-button>
                                <argon-loading-button :is-loading="is_loading"  
                                    :disabled="!is_admin || is_loading" color="success" type="button" 
                                    icon="upload"
                                    class="my-4 mb-2 float-end" variant="dark" @click="addGateway">
                                    <span v-if="formCreate">Afegir</span>
                                    <span v-else>Actualitzar</span>
                                </argon-loading-button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <toast :alertText="alertText" :toast-type="toastType"></toast>
</template>
