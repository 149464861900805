<script setup>
import { ref, onMounted } from "vue";
import { getDataFromLocalStorage } from '../utils/localStorageUtils';
import { useRouter } from 'vue-router';
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonPopupButton from "@/components/ArgonPopupButton.vue";
import GatewayCreate from "./GatewayCreate.vue";
import Map from "@/components/Maps.vue";
import Tabs from "@/components/Tabs.vue";
import gatewayIcon from "@/assets/img/emptyGateway.png";
import EChartComponent from '@/components/EChart.vue';
import ArgonInput from "@/components/ArgonInput.vue";
import { getGateway, deleteGateway, getEventsByGateways } from "../services/services.js";

const gateway = ref(null)
const tenant = ref(null)
const is_admin = ref(false)
const router = useRouter();
const itemId = ref(router.currentRoute.value.params.id);
const showMap = ref(false)
const gatewaysMarkers = ref([])
const noDeleted = ref(false)
const deleteInput = ref(null)
const tabsInfo = ref([
    { text: "Detall", value: "detail" },
    { text: "Configuració", value: "config" },
])
const chartEvents = ref({})
const chartEventsLoaded = ref(false)
let chartColors = null

const deleteGat = (() => {
    noDeleted.value = false
    deleteGateway(gateway.value.gateway_id).then(response => {
        if (response.data === '') {
            router.push({ name: 'Gateways' });
        } else {
            noDeleted.value = true
        }
    })
})

onMounted(() => {
    tenant.value = getDataFromLocalStorage('tenantId')
    is_admin.value = getDataFromLocalStorage('account-data').is_admin
    getGateway(itemId.value).then(response => {
        gateway.value = response.data
        if(gateway.value.tags?.color){
            chartColors = gateway.value.tags?.color
        }
        if (gateway.value.location) {
            gatewaysMarkers.value.push({
                lat: gateway.value.location.lat,
                lng: gateway.value.location.long,
                icon: gatewayIcon,
                color: chartColors,
                content: `<p><small>` + gateway.value.name + `<br>` + gateway.value.gateway_id + `</small></p>`,
            })
        }
        
        showMap.value = true
    })
    getEventsByGateways().then(response => {        
        // nou bloc per echart, contruïm les series i l'eix x
        let eix_x_dies = new Set();
        let gateway_color = {};
        let gateway_per_dia = {}; // serà una estructura "gateway": {"date": "valor"}
        response.data.forEach(item => {
            eix_x_dies.add(item.date);
            if( typeof(gateway_per_dia[ item.gateway_name ]) == "undefined") gateway_per_dia[ item.gateway_name ] = {};
            gateway_per_dia[ item.gateway_name ][ item.date ] = item.count;
            gateway_color[ item.gateway_name ] = chartColors;
        });
        eix_x_dies = [...eix_x_dies]; // convertim el Set() en array

        const series = [];
        let gatewayData = Object.entries(gateway_per_dia).find(el => el[0] == gateway.value.name)
        if(gatewayData){
            var dades = [];
            eix_x_dies.forEach((dia) => {
                dades.push({
                    "value" : gatewayData[1][dia] || '-',
                    itemStyle: {
                        color: gateway_color[ gatewayData[0] ]
                    },
                });
            });
            series.push({
                type: 'bar',
                stack: 'a',
                name: gatewayData[0],
                data: dades
            });
    
           
            chartEvents.value = {
                height: "300px",
                options: { 
                    xAxis: {
                        type: 'category',
                        data: eix_x_dies
                    },
                    yAxis: {
                        name: "Paquets tx",
                        type: 'value',
                        position: 'left',
                    },
                    tooltip: {
                        show: true,
                    },
                    series: series,
                    // legend: {
                    //     // Try 'horizontal'
                    //     orient: 'horizontal',
                    //     right: 10,
                    //     top: 'center'
                    // },
                }
            }
        }
        chartEventsLoaded.value = true
    })
})

</script>
<template>
    <div class="container-fluid pt-4 pb-3">
        <div class="row">
            <div class="col-lg-12">
                <div class="card" v-if="gateway">
                    <div class="card-header pb-0 p-3">
                        <div class="row">
                            <div class="col-6 d-flex align-items-center">
                                <h6>{{ gateway.name }} <span>
                                        <p><small> gateway id: {{ gateway.gateway_id }}</small></p>
                                    </span></h6>
                            </div>
                            <div class="col-6 text-end" v-if="is_admin">
                                <argon-popup-button button-text="Esborrar Gateway" color="danger" size="xs"
                                    variant="outline">
                                    <template #content>
                                        <div>
                                            <p class="mb-1 lh-sm text-xs">
                                                Escriu <strong>{{ gateway.name }}</strong> per confirmar que vols eliminar la gateway:
                                            </p>
                                            <argon-input id="deleteInput" class="mb-0" type="text" v-model="deleteInput" />
                                            <argon-button :disabled="deleteInput != gateway.name" color="success" size="xs" class="mt-2"
                                                @click="deleteGat(gateway.gateway_id)">
                                                Esborrar
                                            </argon-button>
                                            <p v-if="noDeleted" class="text-warning text-xs mb-0 mt-1">No s'ha pogut esborrar la gateway prova-ho més tard.</p>
                                        </div>
                                    </template>
                                </argon-popup-button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-4 pt-0 pb-3" style="min-height: 560px;">
                        <tabs :tabs-info="tabsInfo">
                            <template #detail>
                                <div class="container-fluid py-4">
                                    <div class="row">
                                        <div class="col">
                                            <div class="card">
                                                <div class="card-body p-0 rounded">
                                                    <Map v-if="showMap" id="gatewayMap" class="mb-3 rounded"
                                                        :markers="gatewaysMarkers"
                                                        style="width: 100%; height: 550px; z-index: 1">
                                                    </Map>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="card overflow-auto p-3" style="height: 550px;">
                                                <p v-if="chartEventsLoaded && chartEvents.options?.series?.length > 0" class="mb-0 text-sm text-uppercase text-center font-weight-bold">Número de paquets transmesos l'última setmana</p>
                                                <EChartComponent v-if="chartEventsLoaded && chartEvents.options?.series?.length > 0" :options="chartEvents.options">
                                                </EChartComponent>
                                                <div v-else><p class="text-center mt-5">No hi ha dades de trames</p></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template #config>
                                <gateway-create :form-create="false" :form-data="{
                                    gateway_id: gateway.gateway_id,
                                    name: gateway.name,
                                    description: gateway.description,
                                    stats_interval: gateway.stats_interval,
                                    tags: gateway.tags
                                }" />
                            </template>
                        </tabs>
                        <argon-button color="success" size="xs" class="mt-2"
                            @click="router.push({ name: 'Gateways' });">
                            Tornar
                        </argon-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style></style>