<script setup>
    import { ref, defineProps, computed, useSlots, defineOptions, watch } from 'vue'
    // import VueMultiselect from './Multiselect.vue';
    import ArgonButton from "@/components/ArgonButton.vue";

    
    const props = defineProps({
        tableHeaders: { type: Object, default: () => {} },
        tableItems: { type: Object, default: () => {} },
        itemsSelected: { type: Object, default: () => {} },
        hideRowsPerPage: { type: Boolean, default: false },
    })

    defineOptions({
        inheritAttrs: false
    })

    const emits = defineEmits(['update:itemsSelected']);

    const itemsSelectedInComponent = ref([])

    const slots = useSlots()

    const slotNames = Object.keys(slots)

    const dataTable = ref();
    const currentPageFirstIndex = computed(() => dataTable.value?.currentPageFirstIndex);
    const currentPageLastIndex = computed(() => dataTable.value?.currentPageLastIndex);
    const clientItemsLength = computed(() => dataTable.value?.clientItemsLength);

    // pagination related
    // const maxPaginationNumber = computed(() => dataTable.value?.maxPaginationNumber);
    // const currentPaginationNumber = computed(() => dataTable.value?.currentPaginationNumber);

    const isFirstPage = computed(() => dataTable.value?.isFirstPage);
    const isLastPage = computed(() => dataTable.value?.isLastPage);

    const nextPage = () => {
        dataTable.value.nextPage();
    };
    const prevPage = () => {
        dataTable.value.prevPage();
    };
    // const updatePage = (paginationNumber) => {
    //     dataTable.value.updatePage(paginationNumber);
    // };

    // rows per page related
    const rowsPerPageOptions = computed(() => {
        const rows = dataTable.value?.rowsPerPageOptions
        const result = []
        if (rows){
            rows.forEach(row => {
                result.push({
                    'id': row
                })
            });
        }
        return result
    });
    const rowsPerPageActiveOption = computed(() => [{'id': dataTable.value?.rowsPerPageActiveOption}]);

    const updateRowsPerPageSelect = (e) => {
        dataTable.value.updateRowsPerPageActiveOption(Number(e.target.value));
    };

    watch(() => props.tableItems,(newItems, oldItems) => {
        if (newItems !== oldItems) {
            dataTable.value.currentPaginationNumber = 1; // Reset to the first page when filter changes
        }
    });
    
    watch(() => itemsSelectedInComponent.value,(newVal) => {
        emits('update:itemsSelected', newVal);
    }, { deep: true });


</script>

<template>
    <DataTable
        class="table dataTable"
        ref="dataTable"
        :headers="props.tableHeaders"
        :items="props.tableItems"
        hide-footer
        empty-message="No hi ha dades"
        :rows-per-page="10"
        v-model:items-selected="itemsSelectedInComponent"
        >
        <template v-slot:[slot]="model" v-for="slot in slotNames">
            <slot :name="slot" v-bind="model"></slot>
        </template>
    </DataTable>

    <div class="customize-footer">           

        <div class="customize-rows-per-page me-4 float-end" v-if="!hideRowsPerPage">
            <select class="form-select pe-4" @change="updateRowsPerPageSelect" style="padding: 6px;">
                <option v-for="item in rowsPerPageOptions" :key="item" :id="item.id" :selected="item === rowsPerPageActiveOption"
                    :value="item.id">
                    {{ item.id }}
                </option>
            </select>
        </div>
        <div class="customize-pagination float-end me-2">
            <argon-button color="success" size="sm" variant="outline" class="p-2 me-2" @click="prevPage" :disabled="isFirstPage">
                <font-awesome-icon icon="angle-left" class="icon" />
            </argon-button>
            <argon-button color="success" size="sm" variant="outline" class="p-2" @click="nextPage" :disabled="isLastPage">
                <font-awesome-icon icon="angle-right" class="icon" />
            </argon-button>
            <div class="customize-index">
                {{currentPageFirstIndex}} ~ {{currentPageLastIndex}} de {{clientItemsLength}}
            </div>
        </div>
    </div>
    
</template>
<style>
    .customize-button{
        padding: 20px;
        font-size: 12px;
    }
    .dataTableSelect {
        width: 80px;
        margin-bottom: -10px;
    }
    .customize-index {
        font-size: 12px;
    }
    .customize-footer {
        height: 55px;
    }
    .customize-rows-per-page{
        position: relative;
        z-index: 1000;
    }
    .no-background {
        background: none !important;
    }
    .no-background:hover {
        color: var(--primary-text-color) !important;
        background: none !important;
    }


    .dataTableSelect .multiselect__tags {
        border: none;
        background-color: white;
    }
    .dataTableSelect .multiselect__single {
        background-color: white;
    }
    .dataTableSelect .multiselect__input {
        background-color: white;
    }

</style>