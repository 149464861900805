export function saveDataToLocalStorage(key, value) {
    localStorage.setItem(key, value);
}

export function getDataFromLocalStorage(key) {
    const data = localStorage.getItem(key);
    return JSON.parse(data);
}

export function deleteDataFromLocalStorage(key) {
    localStorage.removeItem(key);
}