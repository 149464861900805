<script setup>
import { ref, defineProps, computed, useSlots, defineOptions, watch, onMounted } from 'vue'

/* eslint-disable */
const props = defineProps({
    tableHeaders: { type: Object, default: () => { } },
    tableItems: { type: Object, default: () => { } },
    totalCount: { type: Number, default: 0 },
    hideRowsPerPage: { type: Boolean, default: false },
    emptyMessage: { type: String, default: "No hi ha dades" },
    hideFooter: { type: Boolean, default: false },
    refreshData: Function
})

defineOptions({
    inheritAttrs: false
})

const items = ref([])

const serverOptions = ref({
    page: 1,
    rowsPerPage: 10,
});

const slots = useSlots()
const loading = ref(false);

const slotNames = Object.keys(slots)

const dataTable = ref();
const currentPageFirstIndex = computed(() => dataTable.value?.currentPageFirstIndex);
const currentPageLastIndex = computed(() => dataTable.value?.currentPageLastIndex);
const serverItemsLength = ref(0);

// pagination related
const maxPaginationNumber = computed(() => dataTable.value?.maxPaginationNumber);
const currentPaginationNumber = computed(() => dataTable.value?.currentPaginationNumber);

const isFirstPage = computed(() => dataTable.value?.isFirstPage);
const isLastPage = computed(() => dataTable.value?.isLastPage);

const nextPage = () => {
    serverOptions.value.page = serverOptions.value.page + 1
};
const prevPage = () => {
    serverOptions.value.page = serverOptions.value.page - 1
};

// rows per page related
const rowsPerPageOptions = ref([10, 25, 50, 100]);

const rowsPerPageActiveOption = computed(() => [{ 'id': dataTable.value?.rowsPerPageActiveOption }]);

const updatePage = (paginationNumber) => {
    dataTable.value.updatePage(paginationNumber);
};

const updateRowsPerPageSelect = (e) => {
    dataTable.value.updateRowsPerPageActiveOption(Number(e.target.value));
};

const loadFromServer = async () => {
    loading.value = true;
    let offset = (serverOptions.value.page * serverOptions.value.rowsPerPage) - serverOptions.value.rowsPerPage
    props.refreshData(offset, serverOptions.value.rowsPerPage).then(response => {
        items.value = response
        loading.value = false;
    });
};

watch(serverOptions, (value) => {
    loadFromServer();
}, { deep: true });

onMounted(() => {
    loadFromServer()
    serverItemsLength.value = props.totalCount
})

</script>

<template>
    <DataTable class="table dataTable h-100" style="min-height: 560px;" v-model:server-options="serverOptions"
        ref="dataTable" :headers="props.tableHeaders" :items="items" :server-items-length="serverItemsLength"
        hide-footer :empty-message=props.emptyMessage>
        <template v-slot:[slot]="model" v-for="slot in slotNames">
            <slot :name="slot" v-bind="model"></slot>
        </template>
    </DataTable>

    <div class="customize-footer" v-if="!hideFooter">
        <div class="customize-rows-per-page me-4 float-end" v-if="!hideRowsPerPage">
            <select class="form-select pe-4" @change="updateRowsPerPageSelect">
                <option v-for="item in rowsPerPageOptions" :key="item" :id="item.id" :selected="item === rowsPerPageActiveOption"
                    :value="item">
                    {{ item }}
                </option>
            </select>
        </div>
        <div class="customize-pagination float-end me-2">
            <button class="btn p-2 me-4 mb-0" @click="prevPage" :disabled="serverOptions.page <= 1">
                <i class="ni ni-bold-left text-success text-sm opacity-10"></i>
            </button>
            <button class="btn p-2 mb-0" @click="nextPage" :disabled="serverItemsLength <= (serverOptions.rowsPerPage * serverOptions.page)">
                <i class="ni ni-bold-right text-success text-sm opacity-10"></i>
            </button>
            <div class="customize-index">
                <small>{{ ((serverOptions.rowsPerPage * serverOptions.page)-serverOptions.rowsPerPage)+1 }} ~ {{ ((serverOptions.rowsPerPage * serverOptions.page)-serverOptions.rowsPerPage)+items.length }} de {{ serverItemsLength }}</small>
            </div>
        </div>
    </div>
</template>

<style>
.vue3-easy-data-table {
    border: none !important;
}

.vue3-easy-data-table__header tr th {
    text-transform: uppercase !important;
    font-weight: bold;
    text-align: center;
    vertical-align: middle !important;
    opacity: 0.6;
    font-size: 0.65rem;
    border-top: none;
    color: #626161;
}

.vue3-easy-data-table__body tr td {
    padding: 1rem !important;
    font-size: 0.75rem !important;
    font-weight: 600;
    color: #626161;
}
</style>