import axios from 'axios';
import { getDataFromLocalStorage, deleteDataFromLocalStorage } from '../utils/localStorageUtils'

const axiosInstance = axios.create({
    headers: {
        Authorization: `Token ${(getDataFromLocalStorage('account-data')) ? getDataFromLocalStorage('account-data')['token'] : null}`
    }
});

export async function responseError(response){
    if(response.data == 'ExpiredSignature'){
        deleteDataFromLocalStorage('account-data')
        deleteDataFromLocalStorage('tenantId')
        window.location.reload()
    }else {
        return response
    }
}

export async function loginService(data) {
    try{
        const response = await axios.post(`${process.env.VUE_APP_BASE_API_URL}/login/`, data)
        return response
    } catch(error){
        return responseError(error.response)
    }
}

export async function autologinService(data) {
    try{
        const response = await axios.post(`${process.env.VUE_APP_BASE_API_URL}/autologin/`, data)
        return response
    } catch(error){
        return responseError(error.response)
    }
}

export async function getGateways(offset=0,limit=10, fullData=false) {
    try{
        let tenant = getDataFromLocalStorage('tenantId')?.id
        const response = await axiosInstance.get(`${process.env.VUE_APP_BASE_API_URL}/gateways/?tenantId=${tenant}&offset=${offset}&limit=${limit}&fullData=${fullData}`)
        return response
    } catch(error){
        return responseError(error.response)
    }
}

export async function getEventsByGateways() {
    try{
        let tenant = getDataFromLocalStorage('tenantId')?.id
        const response = await axiosInstance.get(`${process.env.VUE_APP_BASE_API_URL}/events-by-gateway/?tenantId=${tenant}`)
        return response
    } catch(error){
        return responseError(error.response)
    }
}

export async function getGateway(gateway_id) {
    try{
        const response = await axiosInstance.get(`${process.env.VUE_APP_BASE_API_URL}/gateway/?gatewayId=${gateway_id}`)
        return response
    } catch(error){
        return responseError(error.response)
    }
}

export async function postGateway(formData, create) {
    try{
        let tenant = getDataFromLocalStorage('tenantId')?.id
        if(create){
            const response = await axiosInstance.post(`${process.env.VUE_APP_BASE_API_URL}/gateway/?tenantId=${tenant}`, formData)
            return response
        }else{
            const response = await axiosInstance.put(`${process.env.VUE_APP_BASE_API_URL}/gateway/?tenantId=${tenant}`, formData)
            return response
        }
    } catch(error){
        return responseError(error.response)
    }
}

export async function deleteGateway(gateway_id) {
    try{
        const response = await axiosInstance.delete(`${process.env.VUE_APP_BASE_API_URL}/gateway/?gatewayId=${gateway_id}`)
        return response
    } catch(error){
        return responseError(error.response)
    }
}

export async function getGatewaysCount() {
    try{
        let tenant = getDataFromLocalStorage('tenantId')?.id
        const response = await axiosInstance.get(`${process.env.VUE_APP_BASE_API_URL}/gateways-count/?tenantId=${tenant}`)
        return response
    } catch(error){
        return responseError(error.response)
    }
}

export async function getApplications() {
    try{
        let tenant = getDataFromLocalStorage('tenantId')?.id
        const response = await axiosInstance.get(`${process.env.VUE_APP_BASE_API_URL}/applications/?tenantId=${tenant}`)
        return response
    } catch(error){
        return responseError(error.response)
    }
}

export async function getApplication(appId) {
    try{
        const response = await axiosInstance.get(`${process.env.VUE_APP_BASE_API_URL}/application/?appId=${appId}`)
        return response
    } catch(error){
        return responseError(error.response)
    }
}

export async function getDeviceProfiles() {
    try{
        let tenant = getDataFromLocalStorage('tenantId')?.id
        const response = await axiosInstance.get(`${process.env.VUE_APP_BASE_API_URL}/device-profiles/?tenantId=${tenant}`)
        return response
    } catch(error){
        return responseError(error.response)
    }
}

export async function postApplication(data, create) {
    try{
        let tenant = getDataFromLocalStorage('tenantId')?.id
        if(create){
            const response = await axiosInstance.post(`${process.env.VUE_APP_BASE_API_URL}/application/?tenantId=${tenant}`, data)
            return response
        }else{
            const response = await axiosInstance.put(`${process.env.VUE_APP_BASE_API_URL}/application/?tenantId=${tenant}`, data)
            return response
        }
    } catch(error){
        return responseError(error.response)
    }
}

export async function deleteApplication(app_id) {
    try{
        const response = await axiosInstance.delete(`${process.env.VUE_APP_BASE_API_URL}/application/?appId=${app_id}`)
        return response
    } catch(error){
        return responseError(error.response)
    }
}

export async function getDevicesCount() {
    try{
        let tenant = getDataFromLocalStorage('tenantId')?.id
        const response = await axiosInstance.get(`${process.env.VUE_APP_BASE_API_URL}/devices/?tenantId=${tenant}`)
        return response
    } catch(error){
        return responseError(error.response)
    }
}

export async function getDevice(devId) {
    try{
        const response = await axiosInstance.get(`${process.env.VUE_APP_BASE_API_URL}/device/?devEui=${devId}`)
        return response
    } catch(error){
        return responseError(error.response)
    }
}

export async function postDevice(appId, data, create) {
    try{
        if(create){
            const response = await axiosInstance.post(`${process.env.VUE_APP_BASE_API_URL}/device/?appId=${appId}`, data)
            return response
        }else{
            const response = await axiosInstance.put(`${process.env.VUE_APP_BASE_API_URL}/device/?appId=${appId}`, data)
            return response
        }
    } catch(error){
        return responseError(error.response)
    }
}

export async function postDevices(appId, data) {
    try{
        const response = await axiosInstance.post(
            `${process.env.VUE_APP_BASE_API_URL}/devices/?appId=${appId}`,
            data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            }
        )
        return response
    } catch(error){
        return responseError(error.response)
    }
}

export async function deleteDevice(dev_eui) {
    try{
        const response = await axiosInstance.delete(`${process.env.VUE_APP_BASE_API_URL}/device/?devEui=${dev_eui}`)
        return response
       
    } catch(error){
        return responseError(error.response)
    }
}

export async function getAllAppDevices(app_id) {
    try{
        const response = await axiosInstance.get(`${process.env.VUE_APP_BASE_API_URL}/app-all-devices/?appId=${app_id}`)
        return response
    } catch(error){
        return responseError(error.response)
    }
}

export async function getAppDevices(app_id,offset=0,limit=10, searchText='') {
    try{
        const response = await axiosInstance.get(`${process.env.VUE_APP_BASE_API_URL}/app-devices/?appId=${app_id}&offset=${offset}&limit=${limit}&searchText=${searchText}`)
        return response
    } catch(error){
        return responseError(error.response)
    }
}

export async function downloadData(app_id,start_date, end_date, date_format = 'daily') {
    try{
        let tenant = getDataFromLocalStorage('tenantId')?.id
        const response = await axiosInstance.get(`${process.env.VUE_APP_BASE_API_URL}/app-devices-data/?tenantId=${tenant}&appId=${app_id}&startDate=${start_date}&endDate=${end_date}&dateFormat=${date_format}`)
        return response
    } catch(error){
        return responseError(error.response)
    }
}

export async function downloadLastSeen(app_id) {
    try{
        let tenant = getDataFromLocalStorage('tenantId')?.id
        const response = await axiosInstance.get(`${process.env.VUE_APP_BASE_API_URL}/app-last-seen-data/?tenantId=${tenant}&appId=${app_id}`)
        return response
    } catch(error){
        return responseError(error.response)
    }
}

export async function postActiveDevices(app_id, data) {
    try{
        const response = await axiosInstance.post(`${process.env.VUE_APP_BASE_API_URL}/activate/?appId=${app_id}`, data)
        return response
    } catch(error){
        return responseError(error.response)
    }
}

export async function postDeleteDevices(app_id, data) {
    try{
        const response = await axiosInstance.post(`${process.env.VUE_APP_BASE_API_URL}/delete/?appId=${app_id}`, data)
        return response
    } catch(error){
        return responseError(error.response)
    }
}