<script setup>
import { onMounted, ref } from "vue";
import DeviceCreate from "./DeviceCreate.vue";
import { useRouter } from 'vue-router';
import { getDevice } from "../services/services";
import { getDataFromLocalStorage } from '../utils/localStorageUtils';

const router = useRouter();
const device = ref(null)
const is_admin = ref(false)

onMounted(() => {
    is_admin.value = getDataFromLocalStorage('account-data').is_admin
    getDevice(router.currentRoute.value.params.id).then(response => {
        device.value = response.data
    })
})
</script>


<template>
    <device-create v-if="device"
    :form-create="false"
    :form-data="{ 
        dev_eui: device.dev_eui,
        name: device.name,
        description: device.description,
        device_profile_id: device.device_profile_id,
        skip_fcnt_check: device.skip_fcnt_check ?? false,
        is_disabled: device.is_disabled ?? false,
        variables: device.variables,
        tags: device.tags,
        join_eui: device.join_eui,
        OTAA: device.OTAA }"
    />   
</template>
