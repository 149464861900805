<script setup>
import { ref, computed } from "vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonLoadingButton from "@/components/ArgonLoadingButton.vue";
import ArgonRadio from "@/components/ArgonRadio.vue";
import ArgonCheckbox from "@/components/ArgonCheckbox.vue";
import { downloadData, downloadLastSeen } from "../services/services.js"
import { Download, JsonToCsv } from "../utils/utils.js"
import moment from 'moment'

const props = defineProps({
    appId: { type: String, default: '' },
})

const start_date = ref(null)
const end_date = ref(null)
const errors = ref([])
const dateFormat = ref('daily')
const error_text = ref(null)
const is_loading = ref(false)
const last_reading = ref(false)

const minInitialDate = computed(() => {
    return moment(end_date.value).subtract(2, 'months').format('YYYY-MM-DD');
})
const maxInitialDate = computed(() => {
    return moment(end_date.value).format('YYYY-MM-DD');
})
const minFinalDate = computed(() => {
    return moment(start_date.value).format('YYYY-MM-DD');
})
const maxFinalDate = computed(() => {
    return moment(start_date.value).add(2, 'months').format('YYYY-MM-DD') > moment().format('YYYY-MM-DD') ? moment().format('YYYY-MM-DD') : moment(start_date.value).add(2, 'months').format('YYYY-MM-DD') ;
})

function toggleRadio(event) {
    const selectedOption = event.target.id;
    dateFormat.value = selectedOption
}

const download = (() => {
    is_loading.value = true
    error_text.value = null
    let hasError = false
    errors.value = []
    if(!last_reading.value){
        if(!start_date.value){
            errors.value['start_date'] = true
            hasError = true
        }
        if(!end_date.value){
            errors.value['end_date'] = true
            hasError = true
        }
    }
    if (hasError){
        is_loading.value = false
        return
    }
    if(last_reading.value){
        downloadLastSeen(props.appId).then(response => {
            if(response.status == 200){
                Download(JsonToCsv(response.data), 'last_seen'+'_'+new Date().toISOString().split('T')[0]+'.csv', 'text/csv;charset=utf-8;')
                start_date.value = null
                end_date.value = null
            }else{
                error_text.value = response.data
            }
            is_loading.value = false
            last_reading.value = false
        })
    }else {
        downloadData(props.appId, start_date.value,end_date.value, dateFormat.value).then(response => {
            if(response.status == 200){
                Download(JsonToCsv(response.data),  dateFormat.value??'daily'+'_'+start_date.value+'_'+end_date.value+'.csv', 'text/csv;charset=utf-8;')
                start_date.value = null
                end_date.value = null
            }else{
                error_text.value = response.data
            }
            is_loading.value = false
        })
    }
})

</script>
<template>
    <div class="container-fluid pt-4">
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-header pb-2 p-3 px-4">
                        <div class="row">
                            <div class="col-6 d-flex align-items-center">
                                <h6>Descarrega de dades </h6>
                            </div>
                            <div class="col-6 text-end">
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-4 pt-0 pb-2">
                        <p class="text-xs"><font-awesome-icon icon="info-circle" class="text-sm opacity-10" />
                            Només es permet la descarrega de dos mesos de dades
                        </p>
                        <form role="form">
                            <argon-checkbox v-model="last_reading">
                                <label for="checkLastReading">
                                    Descarregar només les últimes lectures
                                </label>
                            </argon-checkbox>
                            <div class="row">
                                <div class="col-md-6">                            
                                    <label for="start_date"><span class="obligatory">*</span> Data inicial</label>
                                    <argon-input id="start_date" :disabled="last_reading" isRequired type="date" :max="maxInitialDate" :min="minInitialDate" :error="errors['start_date']" v-model="start_date" required aria-label="start_date" />
                                </div>
                                <div class="col-md-6">
                                    <label for="end_date"><span class="obligatory">*</span> Data de fi</label>
                                    <argon-input id="end_date" :disabled="last_reading" isRequired type="date" :error="errors['end_date']" :max="maxFinalDate" :min="minFinalDate" v-model="end_date" required aria-label="end_date" />
                                </div>
                            </div>

                            <argon-radio name="dateFormat" :disabled="last_reading" id="daily" :checked="true" @click="toggleRadio">Lectures diàries</argon-radio>
                            <argon-radio name="dateFormat" :disabled="last_reading" id="hourly" @click="toggleRadio">Consums horàris</argon-radio>
                            <p class="mb-0 pt-2"><span v-if="error_text" class="text-warning">{{ error_text }}</span></p>
                            <p><small class="obligatory">* Dades obligatòries</small></p>
                            <div class="">
                                <argon-loading-button :is-loading="is_loading" icon="download" :disabled="is_loading" color="success" type="button" class="my-4 mb-2 float-end" @click="download">
                                    <span>Descarregar</span>
                                </argon-loading-button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>