<script setup>
import { onBeforeUnmount, onBeforeMount, ref } from "vue";
import { useStore } from "vuex";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonLoadingButton from "@/components/ArgonLoadingButton.vue";
import Logo from "../components/Logo-h.vue";

// Functions
import { useRouter } from 'vue-router';
import { loginService } from '../services/services.js'
import { saveDataToLocalStorage } from '../utils/localStorageUtils';

const body = document.getElementsByTagName("body")[0];

const store = useStore();
const router = useRouter();
const is_loading = ref(false);

const input = ref({
    email: null,
    password: null,
})
const errorText = ref('')

onBeforeMount(() => {
    store.state.hideConfigButton = true;
    store.state.showNavbar = false;
    store.state.showSidenav = false;
    store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
});

const login = (() => {
    errorText.value = ''
    is_loading.value = true
    loginService(input.value).then(response => {
        if(response.status == 403){
            errorText.value = 'Usuari o contrasenya incorrectes'
            is_loading.value = false
        }else if(response.status != 200){
            errorText.value = 'Hi ha hagut un error, prova-ho més tard'
            is_loading.value = false
        }else {
            setTimeout(()=> {
                saveDataToLocalStorage('account-data', JSON.stringify(response.data))
                saveDataToLocalStorage('tenantId', JSON.stringify(response.data?.tenants[0]))
                saveDataToLocalStorage('reload', '1')
                router.push({ path: '/' })
            }, 500)
        }
    })
})

onBeforeUnmount(() => {
    store.state.hideConfigButton = false;
    store.state.showNavbar = true;
    store.state.showSidenav = true;
    store.state.showFooter = true;
    body.classList.add("bg-gray-100");
});
</script>
<template>
    <main class="mt-0 main-content">
        <section>
            <div class="page-header min-vh-100">
                <div class="container">
                    <div class="row">
                        <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
                            <div class="card card-plain">
                                <div style="width: 60%; margin-top: -80px">
                                    <Logo/>
                                </div>
                                <div class="pb-0 card-header text-start">
                                    <h4 class="font-weight-bolder">Inicia sessió</h4>
                                    <p class="mb-0">Entra l'email i la contrasenya per iniciar sessió</p>
                                    <p class="text-danger mb-0 mt-1"><small>{{ errorText }}</small></p>
                                </div>
                                <div class="card-body">
                                    <form>
                                        <div class="mb-3">
                                            <argon-input id="email" type="email" placeholder="Email" name="email"
                                                v-model="input.email" required size="lg" />
                                        </div>
                                        <div class="mb-3">
                                            <argon-input id="password" type="password" placeholder="Contrasenya"
                                                name="password" v-model="input.password" required size="lg" />
                                        </div>
                                        <argon-switch id="rememberMe" name="remember-me">Recorda'm</argon-switch>

                                        <div class="text-center">
                                            <argon-loading-button :is-loading="is_loading"  size="lg" 
                                                :disabled="is_loading" color="success" type="submit" 
                                                icon=""
                                                class="mt-4" variant="gradient" @click="login()">
                                                <span>Iniciar Sessió</span>
                                            </argon-loading-button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div
                            class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column">
                            <div class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                                style="
                                    background-image: url('/iotcom-bg.jpg');
                                    background-size: cover;
                                    ">
                                <span class="mask bg-gradient-success opacity-6"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>
