<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useRouter } from 'vue-router';
import { getDataFromLocalStorage, saveDataToLocalStorage } from '../../../utils/localStorageUtils.js'

import SidenavItem from "./SidenavItem.vue";
// import SidenavCard from "./SidenavCard.vue";

const tenants = ref([])
const selectedTenant = ref(null)
const router = useRouter();

const getRoute = () => {
    const route = useRoute();
    const routeArr = route.path.split("/");
    return routeArr[1];
};

const updateTenant = (() => {
    saveDataToLocalStorage('tenantId', JSON.stringify(selectedTenant.value))
    router.push({ path: '/dashboard' }).then(() => {
        window.location.reload();
    });
})

onMounted(() => {
    tenants.value = getDataFromLocalStorage('account-data')?.tenants
    selectedTenant.value = getDataFromLocalStorage('tenantId')
})

</script>
<template>
    <div class="collapse navbar-collapse w-auto h-auto h-100" id="sidenav-collapse-main">
        <div class="position-relative">
            <div class="position-absolute" style="right: 50px; top: 5px;">
                <font-awesome-icon icon="caret-down" class="text-sm opacity-10" />
            </div>
            <select class="select-items form-control w-75 m-auto mb-3" @change="updateTenant" v-model="selectedTenant">
                <option v-for="tenant, index in tenants" :key="index"
                    :value="tenant">
                    {{ tenant.name }}
                </option>
            </select>
        </div>
        <ul class="navbar-nav">
            <li class="nav-item">
                <sidenav-item to="/dashboard" :class="getRoute() === 'dashboard' ? 'active' : ''"
                    :navText="'Dashboard'">
                    <template v-slot:icon>
                        <i class="ni ni-tv-2 text-primary text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li>

            <li class="nav-item">
                <sidenav-item to="/gateways" :class="getRoute() === 'gateways' ? 'active' : ''"
                    :navText="'Gateways'">
                    <template v-slot:icon>
                        <font-awesome-icon icon="wifi" class="text-warning text-sm opacity-10" />
                    </template>
                </sidenav-item>
            </li>

            <li class="nav-item">
                <sidenav-item to="/applications" :class="getRoute() === 'aplicacions' ? 'active' : ''"
                    :navText="'Aplicacions'">
                    <template v-slot:icon>
                        <i class="ni ni-credit-card text-success text-sm opacity-10"></i>
                    </template>
                </sidenav-item>
            </li>
        </ul>
    </div>

    <div class="pt-3 mx-3 mt-3 sidenav-footer">
    </div>
</template>
